import 'core-js/actual/promise';
import request from "superagent";


if (!window.visionaustralia) {
	window.visionaustralia = {};
}
//top level closure
(function () {

	//define styles
	let cssOffleft = {
		position: "absolute",
		left: "-999px"
	};

	let cssDropdown = {
		listStyleType: "none",
		position: "absolute",
		backgroundColor: "white",
		margin: "0px 2px",
		top: "56px",
		padding: "10px",
		border: "2px solid transparent",
		borderRadius: "5px",
		maxHeight: "300px",
		minWidth: "100px",
		overflow: "auto",
		right: "auto",
		boxShadow: "0 2px 20px rgba(0,0,0,0.1)"
	};

	let cssLi = {
		margin: "0px",
		padding: "0px"
	};

	let cssButton = {
		backgroundColor: "transparent",
		color: "black",
		border: "none",
		fontSize: "16px",
		textAlign: "left",
		margin: "0px",
		padding: "5px",
		width: "100%",
		cursor: "pointer",
		outline: "none !important",
	}

	let cssButtonFocus = {
		color: "black",
		backgroundColor: "lightgray",
	};

	let cssButtonUnFocus = {
		outline: "none !important",
		backgroundColor: "transparent",
		color: "black"
	};


	function ltrim(s) {
		return s.replace(/^\s+/, "");
	}

	//ensure public function is called after page load
	function makeAutocomplete(inputId, selectId) {
		$(function () {
			makeAutocompleteAfterPageLoad(inputId, selectId);
		});
	}

	function makeAutocompleteAfterPageLoad(inputId, matchFunction) {

		//if($("#vaautocompletestyle").length < 1){
		//$('head').append('<style id="vaautocompletestyle" type="text/css">.vaautocomplete, .vaautocomplete * {all:initial;box-sizing:border-box;}</style>');
		//}

		//distance between input element element and visible options
		let topOffset = 5;
		let leftOffset = -1;

		let body = $("body");

		let input = $("#" + inputId);
		let inputLabel = $("label[for=" + inputId + "]");

		let inputText = $('<span> - type to filter then select from list below</span>');
		inputText.css(cssOffleft);

		//inputLabel.append(inputText);

		input.attr("autocomplete", "off");

		let noMatches = "No matches";

		let prevText = "";
		let ul;
		let prevIndex = -1;
		let matches = [];

		let span;

		function removeUl() {
			if (ul) {
				ul.remove();
				ul = null;
				//remove eventlistener from body
				body.off("click", removeUl);
			}
		}

		//use keyup because only then is the actual character in the input field
		input.keyup(function (ev) {
			//if Tab key - ignore
			if (ev.which == 9) {
				return;
			}

			//remove spaces at start
			let text = ltrim(input.val());
			//if no change in text, do nothing
			if (text == prevText) {
				return;
			}


			setTimeout(doRest, 0);

			function doRest() {

				//keep this value for next time keydown event handler is called
				prevText = text;
				//if no matches: insert text "No matches" in ul
				//if some matches: insert the matches in ul
				//ul is only removed if you select an option OR click outside the input field


				//remove visible options
				removeUlContent();


				//could place "Loading" in drop-down


				if (!ul) {
					ul = $('<div role="list" class="vaautocomplete"></div>');
					ul.css(cssDropdown);
					input.after(ul);
					//add event handler to body
					body.on("click", removeUl);
				}

				//store buttons to add event handlers afterwards
				let buttons = [];

				//fill matches array with matching items
				matches = matchFunction(text, addMatches, ul);

				//if no matches
				if (matches.length == 0) {
					if (input.val() != "") {

						//lazy - use fake button so CSS is the same
						let VOItem = $('<button role="presentation">No matches</button>');
						VOItem.css(cssButton);

						VOItem.click(function () {
							removeUlContent();
							removeUl();
							input.focus();
							prevText = input.val();
						});

						VOItem.focus(function () {
							$(this).css(cssButtonFocus);
						});

						VOItem.blur(function () {
							$(this).css(cssButtonUnFocus);
						});

						//ADD DOWN ARROW event handler
						VOItem.keydown(function (ev) {
							//escape
							if (ev.which == 27) {
								console.log('key down l182');
								removeUlContent();
								input.focus();
							}
						});

						VOItem.keydown(function (ev) {
							//uparrow
							if (ev.which == 38) {
								input.focus();
								//removeUlContent();
							}
						});

						let li = $('<div role="listitem"></div>');
						li.css(cssLi);
						li.append(VOItem);
						ul.append(li);

						buttons.push(VOItem);

					} else {
						removeUlContent();
						removeUl();
					}
				}


				//addMatches(matches,ul);

				//recursive function
				function addMatches(matches, container) {
					//adds <li> elements to <ul> element (container)

					if (matches.length == 0) return;

					let lastListItem = null;

					//add matches to list
					for (let i = 0, len = matches.length; i < len; i++) {

						if (matches[i] instanceof Array) {
							let listEl = $('<div role="list"></div>');
							if (!lastListItem) {
								let listIt = $('<div role="listitem"></div>');
								listIt.css(cssLi);
								addMatches(matches[i], listEl);
								listIt.append(listEl);
								container.append(listIt);
							} else {
								addMatches(matches[i], listEl);
								lastListItem.append(listEl);
							}
							continue;
						}

						let textVal = matches[i];

						let VOItem = $('<button>' + textVal + '</button>');
						VOItem.attr("aria-label", VOItem.text());
						VOItem.css(cssButton);

						//this closes the visible options and finalses selection
						VOItem.click(function (e) {

							let t = matches[i];

							//place text of selected item in input field
							input.val($(this).text());
							//remove visible drop-down
							removeUlContent();
							removeUl();

							//place focus on input element
							input.focus();
							//ensure that options do not show next time input field gets a keypress
							prevText = input.val();
						});

						VOItem.focus(function () {
							$(this).css(cssButtonFocus);
						});

						VOItem.blur(function () {
							$(this).css(cssButtonUnFocus);
						});

						buttons.push(VOItem);

						lastListItem = $('<div role="listitem"></div>');
						lastListItem.css(cssLi);
						lastListItem.append(VOItem);
						container.append(lastListItem);
					}

					//add event handlers to buttons, bar first and last
					for (let k = 1, klen = buttons.length - 1; k < klen; k++) {
						(function (k) {
							let VOItem = buttons[k];

							//ADD DOWN ARROW event handler
							VOItem.keydown(function (ev) {
								//escape
								if (ev.which == 27) {
									removeUlContent();
									removeUl();
									input.focus();
									//if downarrow - move keyboard focus to the select element
								} else if (ev.which == 40) {

									buttons[k + 1].focus();
									//alert(k+1);
									ev.preventDefault();
									//ev.stopPropagation();
									//uparrow
								} else if (ev.which == 38) {

									buttons[k - 1].focus();

									//aler(prevButton.nodeName);
									ev.preventDefault();
								}
							});

						})(k);
					}

					if (buttons.length > 1) {
						//add event handlers to first button
						let VOItem = buttons[0];
						VOItem.keydown(function (ev) {
							//escape
							if (ev.which == 27) {
								removeUlContent();
								removeUl();
								input.focus();
								//if downarrow
							} else if (ev.which == 40) {

								buttons[1].focus();

								ev.preventDefault();
								//ev.stopPropagation(); xxxx
								//uparrow
							} else if (ev.which == 38) {

								input.focus();
								ev.preventDefault();
							}
						});

						//add event handlers to last button
						let klen = buttons.length-1;
						VOItem = buttons[klen];
						VOItem.keydown(function (ev) {
							//escape
							if (ev.which == 27) {
								removeUlContent();
								removeUl();
								input.focus();
								//if downarrow
							} else if (ev.which == 40) {

								ev.preventDefault();
								//uparrow
							} else if (ev.which == 38) {

								buttons[klen - 1].focus();
								ev.preventDefault();
							}
						});

					} else if (buttons.length == 1) {
						let VOItem = buttons[0];
						VOItem.keydown(function (ev) {
							//escape
							if (ev.which == 27) {
								removeUlContent();
								removeUl();
								input.focus();
								//if downarrow
							} else if (ev.which == 40) {
								ev.preventDefault();
								//uparrow
							} else if (ev.which == 38) {
								input.focus();
								ev.preventDefault();
							}
						});
					}

				}

				let pos = input.offset();
				position(input, ul);

				input.after(ul);
			}

			//end keyup keyup keyup keyup keyup keyup keyup keyup keyup keyup keyup keyup keyup keyup keyup
		});


		input.keydown(function (ev) {
			//down arrow, move to suggestions
			if (ev.which == 40) {
				if (ul) {
					//span.focus();
					setTimeout(function () {
						ul.children(":first-child").children().focus();
					}, 100);
				}
				ev.preventDefault();
				return;
			}
			// enter key, submit the form before any button
			// gets to replace the text
			if (ev.which == 13) {
				this.form.submit();
				ev.preventDefault();
			}
		});


		function removeUlContent() {
			if (ul) ul.empty();
			if (span) span.remove();
			//span = null;
			//reset index of current selection
			prevIndex = -1;
		}

		$(window).resize(function () {
			position(input, ul);
		});

		function position(original, newcomer) {
			if (!newcomer) return;
			let o = original.position();
			let h = original.height();
			let w = original.innerWidth();//width plus padding
			w = w - parseInt(newcomer.css("padding-left")) - parseInt(newcomer.css("padding-right"));
			newcomer.css({
				             'top': o.top + h + 30 + topOffset + 'px', // push down the autocomplete box
				             'left': o.left + leftOffset + 'px',
				             'min-width': w + 'px'
			             }).show();
		}

	}//end makeAutoComplete

	//add public function
	window.visionaustralia.makeAutocomplete = makeAutocomplete;

//end top level closure
})();


// START
$(() => {
	prepareLinks();
	processClickedItems();
});


function amMatch(inputText, callback, container) {

	// prepare to call the callback when the response arrives
	request
		.get('/search/suggest/?q=' + inputText)
		.then(result => {
			callback(
				JSON.parse(result.text),
				container
			)
		});
	// immediately return for the moment
	return ["Loading..."];
}

function prepareLinks() {
	let searchLinks = $('.search-result__link');

	searchLinks.on('click', function (e) {
		let ajax_url = $(this).data('ajax-url');
		let hitsToRecord = JSON.parse(localStorage.getItem('search_hits_to_record') || "[]");
		hitsToRecord.push({'url': ajax_url, 'identifier': new Date().getTime()});
		localStorage.setItem('search_hits_to_record', JSON.stringify(hitsToRecord));
	});

	visionaustralia.makeAutocomplete("body-search-input", amMatch);
	visionaustralia.makeAutocomplete("nav-search-input", amMatch);
}

function processClickedItems() {

	while (true) {
		// fetch afresh from localStorage each time (in case another tab is also processing)
		let hitsToRecord = JSON.parse(localStorage.getItem('search_hits_to_record') || "[]");

		// break the loop if no more to process after this one
		if (hitsToRecord.length == 0) {
			break;
		}
		let poppedItem = hitsToRecord.pop();

		if (typeof poppedItem !== "undefined") {
			// set the stored value before another process gets it
			localStorage.setItem('search_hits_to_record', JSON.stringify(hitsToRecord));
			window.get_csrf_token_value().then(csrfToken => {
			request
				.post('/search/search_perf/')
				.type('form')
				.send({
					      'csrfmiddlewaretoken': csrfToken,
					      'dest': poppedItem['url'],
					      'request_identifier': poppedItem['identifier']
				      })
				.catch(err => {
					// if there's an error we lose this count
					// we don't put it back in the list because if there's a problem
					// then people will end up with things in their localStorage list
					// that never can be cleared, and a single hit is not that important.
					console.log(err.message, []);
				});
			});
		}
	}
}

